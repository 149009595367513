import appConfig from "./configs/appConfig"

export type SharedSessionStorageEventType = 'clear' | 'removeItem' | 'setItem'

export class SharedSessionStorageEvent extends Event {
    data: any
    constructor(type: SharedSessionStorageEventType, data?: any) {
        super(type)
        this.data = data
    }
}
export class SharedSessionStorage implements Storage {

    private static _ORIGIN = window.location.origin
    private static _SHARED_KEYS_KEY = `${appConfig.appFullName}:sharedKeys`

    private _logs: { action: 'onmessage' | 'postmessgae', type: string, payload: any }[]
    private _sharedKeys: string[]
    private _broadcastChannel: BroadcastChannel
    private _eventListeners: { [type: string]: ((e: SharedSessionStorageEvent) => void)[] }

    constructor() {
        // init _logs
        this._logs = []
        // init _sharedKeys
        const sharedKeysJson = sessionStorage.getItem(SharedSessionStorage._SHARED_KEYS_KEY)
        if (sharedKeysJson) {
            this._sharedKeys = JSON.parse(sharedKeysJson)
        } else {
            this._sharedKeys = []
            sessionStorage.setItem(SharedSessionStorage._SHARED_KEYS_KEY, JSON.stringify(this._sharedKeys))
        }
        // init _broadcastChannel
        this._broadcastChannel = new BroadcastChannel('sharedSessionStorage')
        this._broadcastChannel.addEventListener('message', e => {
            // ignore untrusted or different origin
            if (!e.isTrusted || e.origin !== SharedSessionStorage._ORIGIN) {
                return
            }
            // ignore unknown format
            if (!e.data || !e.data.type) {
                return
            }
            // on message
            const { type, payload } = e.data
            this._onMessage(type, payload)
        })
        // init _eventListeners
        this._eventListeners = {}
        // post sync request
        this._postMessage('sync')
    }

    private _log(action: 'onmessage' | 'postmessgae', type: string, payload?: any) {
        if (this._logs.length === 100) {
            this._logs.shift()
        }
        this._logs.push({ action, type, payload })
    }

    private _onMessage(type: string, payload?: any) {
        this._log('onmessage', type, payload)
        switch (type) {
            case 'sync':
                this._postMessage('dump', this._dump())
                break
            case 'dump':
                this._load(payload)
                break
            case 'clear':
                sessionStorage.clear()
                this._triggerEvent('clear')
                break
            case 'removeItem':
                sessionStorage.removeItem(payload.key)
                this._triggerEvent('removeItem', payload)
                break
            case 'setItem':
                sessionStorage.setItem(payload.key, payload.value)
                this._triggerEvent('setItem', payload)
                break
            default:
                break
        }
    }

    private _postMessage(type: string, payload?: any) {
        this._log('postmessgae', type, payload)
        this._broadcastChannel.postMessage({ type, payload })
    }

    private _dump(): Record<string, string> {
        const record: Record<string, string> = {}
        record[SharedSessionStorage._SHARED_KEYS_KEY] = JSON.stringify(this._sharedKeys)
        for (const key of this._sharedKeys) {
            record[key] = sessionStorage.getItem(key)!
        }
        return record
    }

    private _load(record: Record<string, string>) {
        if (this._sharedKeys.length === 0) {
            this._sharedKeys = JSON.parse(record[SharedSessionStorage._SHARED_KEYS_KEY])
            sessionStorage.setItem(SharedSessionStorage._SHARED_KEYS_KEY, JSON.stringify(this._sharedKeys))
            for (const key of this._sharedKeys) {
                sessionStorage.setItem(key, record[key])
            }
        }
    }

    private _triggerEvent(type: SharedSessionStorageEventType, data?: any) {
        if (this._eventListeners[type]) {
            const event = new SharedSessionStorageEvent(type, data)
            for (const callback of this._eventListeners[type]) {
                callback(event)
            }
        }
    }

    get sharedKeys() {
        return this._sharedKeys
    }

    get length() {
        return sessionStorage.length
    }

    clear(): void {
        for (const key of this._sharedKeys) {
            sessionStorage.removeItem(key)
        }
        this._sharedKeys = []
        sessionStorage.setItem(SharedSessionStorage._SHARED_KEYS_KEY, JSON.stringify(this._sharedKeys))
        this._postMessage('clear')
    }

    getItem(key: string): string | null {
        if (this._sharedKeys.indexOf(key) >= 0) {
            return sessionStorage.getItem(key)
        } else {
            return null
        }
    }

    key(index: number): string | null {
        const key = this._sharedKeys[index]
        return key === undefined ? null : key
    }

    removeItem(key: string): void {
        if (this._sharedKeys.indexOf(key) >= 0) {
            sessionStorage.removeItem(key)
            this._sharedKeys.splice(this._sharedKeys.indexOf(key), 1)
            sessionStorage.setItem(SharedSessionStorage._SHARED_KEYS_KEY, JSON.stringify(this._sharedKeys))
            this._postMessage('removeItem', { key })
        }
    }

    setItem(key: string, value: string): void {
        sessionStorage.setItem(key, value)
        if (this._sharedKeys.indexOf(key) < 0) {
            this._sharedKeys.push(key)
            sessionStorage.setItem(SharedSessionStorage._SHARED_KEYS_KEY, JSON.stringify(this._sharedKeys))
        }
        this._postMessage('setItem', { key, value })
    }

    addEventListener(type: string, callback: (e: SharedSessionStorageEvent) => void) {
        const callbacks = this._eventListeners[type] || (this._eventListeners[type] = [])
        callbacks.push(callback)
    }

    removeEventListener(type: string, callback: (e: SharedSessionStorageEvent) => void) {
        const callbacks = this._eventListeners[type]
        if (callbacks) {
            const index = callbacks.indexOf(callback)
            if (index >= 0) {
                callbacks.splice(index, 1)
            }
        }
    }
}
export type FunctionId = string | number

export class FunctionStore {
    private _funcs: Record<string, Function>

    constructor() {
        this._funcs = {}
    }

    register(func: Function): FunctionId {
        const functionId = Date.now() + Math.random().toString()
        this._funcs[functionId] = func
        return functionId
    }

    unregister(functionId: FunctionId): Function {
        const func = this._funcs[functionId]
        delete this._funcs[functionId]
        return func
    }

    functionIds(): FunctionId[] {
        return Object.keys(this._funcs)
    }

    dispatch(functionId: FunctionId, ...args: any[]): any {
        const func = this._funcs[functionId]
        if (func) {
            return func(...args)
        } else {
            throw new Error('No such function, id = ' + functionId)
        }
    }
}

function createFunctionStore(): FunctionStore {
    return new FunctionStore()
}

const store = createFunctionStore()

export default store
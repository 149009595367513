import '@fontsource/montserrat'
import { registerApplication, start } from "single-spa"
import ApplicationProps from "./ApplicationProps"
import functionStore from './functionStore'
import history from './history'
import './index.css'
import { SharedSessionStorage } from "./SharedSessionStorage"
import store from './store'

window.sharedSessionStorage = new SharedSessionStorage()

registerApplication<ApplicationProps>({
  name: "base",
  app: () => System.import("@webapp/base"),
  activeWhen: () => true,
  customProps: {
    history,
    store,
    functionStore,
    domElement: document.getElementById('webapp-base'),
  }
})

registerApplication<ApplicationProps>({
  name: "online-chat",
  app: () => System.import("@webapp/online-chat"),
  activeWhen: () => true,
  customProps: {
    history,
    store,
    functionStore,
    domElement: document.getElementById('webapp-online-chat'),
  }
})

registerApplication<ApplicationProps>({
  name: "lcbm",
  app: () => System.import("@webapp/lcbm"),
  activeWhen: () => true,
  customProps: {
    history,
    store,
    functionStore,
    domElement: document.getElementById('webapp-lcbm'),
  }
})

start({
  urlRerouteOnly: true,
})
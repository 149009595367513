import { combineReducers, configureStore, EnhancedStore, ReducersMapObject } from "@reduxjs/toolkit"

interface Unmount {
    (): void
}

interface Mount {
    (key: string, reducer: (state: any, action: any) => any): Unmount
}

export type SharedStore = EnhancedStore & { mount: Mount }

function createStore(): SharedStore {
    const reducers: ReducersMapObject = {}
    const store = configureStore({
        reducer: (state = {}) => state,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false
        }),
    })
    const mount: Mount = (key, reducer) => {
        reducers[key] = reducer
        store.replaceReducer(combineReducers(reducers))
        return () => {
            delete reducers[key]
            store.replaceReducer(combineReducers(reducers))
        }
    }
    return Object.assign(store, { mount })
}

const store = createStore()

export default store